<template>
  <nav>
    <router-link to="/">主页</router-link> |
    <router-link to="/goldlistview">黑本排行榜</router-link>
    <router-link to="/teamview">团队管理</router-link>
    <router-link to="/templateview">团队模板</router-link>
    <router-link v-if="isLoggedIn" @click.prevent="logout" to="/">注销</router-link>
    <router-link v-else to="/loginview">登录</router-link>
  </nav>
  <router-view/>
</template>

<script setup>

//    <router-link to="/loginview">登录</router-link>

//  <p>{{globalGoldListData}}</p>

// import { computed } from 'vue';
// import { useStore } from 'vuex';

// const store = useStore();
// const globalGoldListData = computed(() => store.state.globalGoldListData);

// // 在组件挂载时获取数据
// store.dispatch('fetchGlobalGoldListData');

import { onMounted } from 'vue'
import { computed} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex';
const store = useStore();
const router = useRouter()

// 计算属性检测是否登录
const isLoggedIn = computed(() => {
  return (
    localStorage.getItem('input_qq') &&
    localStorage.getItem('input_group') &&
    localStorage.getItem('input_password')
  )
})

// 注销功能，清除 localStorage 数据
const logout = () => {
  localStorage.removeItem('input_qq')
  localStorage.removeItem('input_group')
  localStorage.removeItem('input_password')
  router.push('/loginview')
}

// 页面加载时进行检测
// onMounted(() => {
//   if (!isLoggedIn.value) {
//     router.push('/loginview')
//   }
// })

//页面加载时进行检测
onMounted(() => {
  if (isLoggedIn.value) {
    store.dispatch('initializeWebSocket');
  }
})


</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 0px;
  a {
    padding: 6px;
    font-weight: bold;
    color: #ffffff;

    &.router-link-exact-active {
      color: #ffa31a;
    }
  }
}
</style>
