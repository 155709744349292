<template>
  <div class="home">
    <p style="text-align:center;"><img src="../assets/logo.png" width="256"></p>
    <p style="color:#ffa31a;font-size:36px;text-align:center;">专业团队摸鱼平台</p>
    <!-- <HelloWorld /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  }
}
</script>
