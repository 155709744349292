// import { createStore } from 'vuex'

// export default createStore({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
import { createStore } from 'vuex';
import axios from 'axios';
import { ElMessage } from 'element-plus'

// It is a good practice to separate API endpoint configurations from the store logic
//const API_BASE_URL = 'http://192.168.123.100:3000';'https://taohuahub.com';
//871902240
//863395722

const API_BASE_URL = 'https://taohuahub.com';


export default createStore({
  state() {
    return {
      globalGoldListData: null,
      globalCount: 0,
      globalTeamData: null,
      websocket: null,  // 用于存储WebSocket连接
    };
  },
  mutations: {
    setGlobalGoldListData(state, data) {
      state.globalGoldListData = data;
    },
    setGlobalCount(state){
      state.globalCount++
    },
    setGlobalTeamData(state, data) {
      state.globalTeamData = data;
    },
    setWebSocket(state, websocket) {
      state.websocket = websocket;
    },
  },
  actions: {
    fetchGlobalGoldListData({ commit }) {
      // axios.get('/data.json').then(response => {
      //   commit('setGlobalData', response.data);

      axios.get(API_BASE_URL+'/api/team/goldlist', {
        params: {
          qq: '616196450',
          group_id: '863395722'
        }
      }).then(response => {
        commit('setGlobalGoldListData', response.data);
      }).catch(error => {
        console.error('Failed to fetch global data:', error);
        // Optionally, handle errors in state or inform the user
      });
    },

    //wss://taohuahub.com/api/socket

    initializeWebSocket({ commit, state, dispatch }) {
      if (state.websocket) {
        state.websocket.close();
      }
      const ws = new WebSocket('wss://taohuahub.com/api/socket'); // 确保替换为实际的WebSocket URL
      //const ws = new WebSocket('ws://127.0.0.1:3000/api/socket'); // 确保替换为实际的WebSocket URL

      ws.onopen = () => {
        console.log('WebSocket connection opened');
        ws.send(JSON.stringify(
          {
          "qq": localStorage.getItem('input_qq'),
          "group_id": localStorage.getItem('input_group'),
          "password": localStorage.getItem('input_password'),
          "getpost": "0"
          }
        ));
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.code == 1) {
          commit('setGlobalTeamData', data);
        }
        if (data.code == 0) {ElMessage({
          message: '数据保存成功！',
          type: 'success',
        })}
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.onclose = () => {
        console.log('WebSocket connection closed, attempting to reconnect');
        setTimeout(() => {
          dispatch('initializeWebSocket');
        }, 1000); // 尝试重连
      };

      commit('setWebSocket', ws);
    },
    updateGlobalTeamData({ state }) {
      if (state.websocket && state.websocket.readyState === WebSocket.OPEN) {

        //var groupsquadjsondata = JSON.stringify(state.globalTeamData.groupsquad);
        //var teamsquadjsondata = JSON.stringify(state.globalTeamData.teamsquad);

        //console.log(groupsquadjsondata);
        //console.log(teamsquadjsondata);


        state.websocket.send(JSON.stringify(
          {
          "qq": localStorage.getItem('input_qq'),
          "group_id": localStorage.getItem('input_group'),
          "password": localStorage.getItem('input_password'),
          "getpost": "1",
          "groupsquad": JSON.stringify(state.globalTeamData.groupsquad),
          "teamsquad": JSON.stringify(state.globalTeamData.teamsquad),
          }
        ));
      } else {
        console.error('WebSocket is not open. Cannot send update.');
      }
    }
  
  }
});